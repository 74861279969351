import styled from 'styled-components'
import { size } from '../../../constants'

export const Wrapper = styled.div`
  background: #2963a3;
  padding-bottom: 124px;
  padding-top: 72px;

  @media (max-width: ${size.md}) {
    padding-bottom: 100px;
    padding-top: 48px;
  }

  @media (max-width: ${size.sm}) {
    padding-bottom: 83px;
    padding-top: 32px;
  }

  @media (max-width: ${size.xs}) {
    padding-bottom: 86px;
    padding-top: 32.5px;
  }
`

export const Container = styled.div`
  display: flex;
  background: #2963a3;
  padding-top: 52px;
  gap: 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Content = styled.div`
  width: 792px;
  padding: 40px 24px;
  border-radius: 24px;
  border: 1px solid #dedede;
  background: white;

  @media (max-width: ${size.lg}) {
    width: 632px;
  }

  @media (max-width: ${size.sm}) {
    width: 100%;
    padding: 44px 24px 40px;
  }

  @media (max-width: ${size.xs}) {
    padding: 48px 24px 24px;
  }
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 16px;
`

export const IconWrapper = styled.div`
  text-align: center;
  margin-top: -92px;
`
