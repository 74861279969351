import situationOne from '../../../../static/img/Kpt/situation-one.png'
import situationOne1024 from '../../../../static/img/Kpt/situation-one-1024.png'
import situationOne320 from '../../../../static/img/Kpt/situation-one-320.svg'
import situationOne414 from '../../../../static/img/Kpt/situation-one-414.svg'
import situationOne768 from '../../../../static/img/Kpt/situation-one-768.svg'
import situationThree from '../../../../static/img/Kpt/situation-three.svg'
import situationThree1024 from '../../../../static/img/Kpt/situation-three-1024.svg'
import situationThree320 from '../../../../static/img/Kpt/situation-three-320.svg'
import situationThree414 from '../../../../static/img/Kpt/situation-three-414.svg'
import situationThree768 from '../../../../static/img/Kpt/situation-three-768.svg'
import situationTwo from '../../../../static/img/Kpt/situation-two.svg'
import situationTwo1024 from '../../../../static/img/Kpt/situation-two-1024.svg'
import situationTwo320 from '../../../../static/img/Kpt/situation-two-320.svg'
import situationTwo414 from '../../../../static/img/Kpt/situation-two-414.svg'
import situationTwo768 from '../../../../static/img/Kpt/situation-two-768.svg'

import styled from 'styled-components'
import { size } from '../../../constants'

export const Wrapper = styled.div`
  background-color: #fff;
`

export const Content = styled.div`
  padding: 72px 0;

  @media (max-width: ${size.md}) {
    padding: 48px 0;
  }

  @media (max-width: ${size.sm}) {
    padding: 32px 0;
  }
`
export const TitleBr = styled.br`
  @media (max-width: ${size.md}) {
    display: none;
  }
`
export const ListWrapper = styled.ul`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  padding-left: 25px;
  list-style-type: disc;
`
export const ListItem = styled.li`
  gap: 8px;
  color: #333;
`

export const CardContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 36px;

  @media (max-width: ${size.md}) {
    flex-direction: column;
    margin-bottom: 48px;
    align-items: flex-start;
  }

  @media (max-width: ${size.sm}) {
    margin-bottom: 32px;
  }

  &:last-child {
    margin-bottom: 0;
  }
`

export const TitleSection = styled.div`
  color: #333333;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;

  @media (max-width: ${size.md}) {
    max-width: 100%;
  }

  @media (max-width: ${size.sm}) {
    font-size: 24px;
    line-height: 32px;
  }

  @media (max-width: ${size.xs}) {
    font-size: 18px;
    line-height: 26px;
  }
`

export const TitleSectionH3 = styled.div`
  color: #333333;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;

  @media (max-width: ${size.sm}) {
    font-size: 18px;
    line-height: 26px;
  }

  @media (max-width: ${size.xs}) {
    font-size: 16px;
    line-height: 24px;
  }
`

export const BlueCard = styled.div`
  background-color: #dfebf9;
  padding: 24px;
  border-radius: 24px;

  @media (max-width: ${size.lg}) {
    padding: 35px 24px;
  }
`

export const DescriptionWrapper = styled.div`
  color: ${(props) => props.color ?? '#333'};

  > * {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
`

export const SituationOneIcon = styled.img.attrs(() => ({}))`
  content: url(${situationOne});
  width: 588px;
  height: 486px;

  @media (max-width: ${size.lg}) {
    content: url(${situationOne1024});
    width: 468px;
    height: 468px;
  }

  @media (max-width: ${size.md}) {
    content: url(${situationOne768});
    width: 100%;
    height: 341px;
  }

  @media (max-width: ${size.sm}) {
    content: url(${situationOne414});
  }

  @media (max-width: ${size.xs}) {
    content: url(${situationOne320});
  }
`

export const SituationTwoIcon = styled.img.attrs(() => ({}))`
  content: url(${situationTwo});
  width: 588px;
  height: 486px;

  @media (max-width: ${size.lg}) {
    content: url(${situationTwo1024});
    width: 468px;
    height: 468px;
  }

  @media (max-width: ${size.md}) {
    content: url(${situationTwo768});
    width: 100%;
    height: 341px;
  }

  @media (max-width: ${size.sm}) {
    content: url(${situationTwo414});
  }

  @media (max-width: ${size.xs}) {
    content: url(${situationTwo320});
  }
`

export const SituationThreeIcon = styled.img.attrs(() => ({}))`
  content: url(${situationThree});
  width: 588px;
  height: 486px;

  @media (max-width: ${size.lg}) {
    content: url(${situationThree1024});
    width: 468px;
    height: 468px;
  }

  @media (max-width: ${size.md}) {
    content: url(${situationThree768});
    width: 100%;
    height: 341px;
  }

  @media (max-width: ${size.sm}) {
    content: url(${situationThree414});
  }

  @media (max-width: ${size.xs}) {
    content: url(${situationThree320});
  }
`

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ImageTwoWrapper = styled(ImageWrapper)`
  @media (max-width: ${size.md}) {
    order: 2;
  }
`

export const InfoContainer = styled.div`
  max-width: 588px;

  @media (max-width: ${size.lg}) {
    max-width: 468px;
  }

  @media (max-width: ${size.md}) {
    max-width: 100%;
  }
`
