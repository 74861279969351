import React from 'react'
import ruporSVG from '../../../../static/img/Kpt/rupor.svg'
import styled from 'styled-components'
import {
  Container,
  Content,
  DescriptionWrapper,
  IconWrapper,
  TextWrapper,
  TitleCard,
  Wrapper
} from './WhatIsKpt.styles'
import { Grid, Text } from '../../../../youtalk-storybook/src/ui'

export const RuporSVG = styled.img.attrs(() => ({
  src: ruporSVG
}))``

export const WhatIsKpt = () => (
  <Wrapper>
    <section>
      <Grid>
        <Container>
          <Content>
            <IconWrapper>
              <RuporSVG />
            </IconWrapper>
            <TextWrapper>
              <TitleCard>
                Что такое когнитивно-поведенческая психотерапия?
              </TitleCard>
              <DescriptionWrapper>
                <Text.Large semiBold>
                  Это подход в психотерапии, который основан на идее, что мысли
                  человека влияют на его действия и чувства. Соответственно,
                  изменения в мышлении меняют поведение и эмоциональный фон.
                </Text.Large>
                <Text.Large semiBold>
                  КПТ — структурный и последовательный метод. Обычно
                  КПТ-терапевт предлагает клиенту упражнения и схемы, дает
                  домашние задания.
                </Text.Large>
              </DescriptionWrapper>
            </TextWrapper>
          </Content>
        </Container>
      </Grid>
    </section>
  </Wrapper>
)
