import React from 'react'
import {
  BlueCard,
  Br,
  CardContainer,
  Content,
  DescriptionWrapper,
  InfoContainer,
  TitleSectionH3,
  Wrapper
} from './DescriptionKpt.styles'
import { Grid, Text } from '../../../../youtalk-storybook/src/ui'
import { TitleWrapper } from '../Common.styles'

export const DescriptionKpt = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <CardContainer>
            <InfoContainer>
              <TitleWrapper>
                <TitleSectionH3>
                  С точки зрения КПТ, наши чувства и действия зависят от мыслей.
                </TitleSectionH3>
              </TitleWrapper>
              <DescriptionWrapper>
                <Text.Large semiBold>
                  Чтобы жизнь поменялась, нужно проверять,
                  <Br /> не искажают ли наши мысли действительность.
                </Text.Large>
                <Text.Large semiBold>
                  Сначала это приходится делать намеренно, но через некоторое
                  время навык становится автоматическим.
                </Text.Large>
              </DescriptionWrapper>
            </InfoContainer>

            <BlueCard>
              <TitleWrapper>
                <TitleSectionH3>
                  Важный компонент КПТ — это ее прозрачность
                </TitleSectionH3>
              </TitleWrapper>
              <DescriptionWrapper>
                <Text.Large semiBold>
                  Психолог объяснит, как работает психика и чем помогут
                  рекомендованные техники.
                </Text.Large>
                <Text.Large semiBold>
                  Цель КПТ — решить ваш запрос и дать навыки, которыми вы
                  сможете применять самостоятельно после завершения
                  психотерапевтической работы.
                </Text.Large>
              </DescriptionWrapper>
            </BlueCard>
          </CardContainer>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
