import exampleOneIcon from '../../../../static/img/Kpt/example-one.svg'
import exampleTwoIcon from '../../../../static/img/Kpt/example-two.svg'
import styled from 'styled-components'
import { size } from '../../../constants'

export const TitleSection = styled.div`
  text-align: center;
  color: #333;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;

  @media (max-width: ${size.sm}) {
    text-align: left;
    max-width: 100%;
    font-size: 24px;
    line-height: 32px;
  }

  @media (max-width: ${size.xs}) {
    font-size: 18px;
    line-height: 26px;
  }
`

export const Content = styled.div`
  padding: 72px 0;
  margin: 0 auto;

  @media (max-width: ${size.md}) {
    padding: 48px 0;
  }

  @media (max-width: ${size.sm}) {
    padding: 32px 0;
  }
`

export const BlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
`

export const ExampleOneImage = styled.img.attrs(() => ({
  src: exampleOneIcon
}))`
  width: 430px;
  height: 420px;

  @media (max-width: ${size.md}) {
    width: 349px;
    height: 341px;
  }

  @media (max-width: ${size.xs}) {
    width: 288px;
    height: 281px;
  }
`

export const ExampleTwoImage = styled.img.attrs(() => ({
  src: exampleTwoIcon
}))`
  width: 430px;
  height: 409px;

  @media (max-width: ${size.md}) {
    width: 358px;
    height: 341px;
  }

  @media (max-width: ${size.xs}) {
    width: 288px;
    height: 274px;
  }
`

export const DescriptionWrapper = styled.div`
  flex: 1;
  color: ${(props) => props.color ?? '#333333'};

  > * {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
  max-width: 486px;
  margin-bottom: 24px;

  @media (max-width: ${size.md}) {
    max-width: 100%;
  }

  @media (max-width: ${size.sm}) {
    margin-bottom: 16px;
  }
`

export const QuoteText = styled.p`
  color: #333;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;

  @media (max-width: ${size.sm}) {
    font-size: 18px;
    line-height: 26px;
  }

  @media (max-width: ${size.xs}) {
    font-size: 16px;
    line-height: 24px;
  }
`

export const ImageOneWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 588px;
  height: 486px;

  @media (max-width: ${size.lg}) {
    min-width: 468px;
    height: 468px;
  }

  @media (max-width: ${size.md}) {
    min-width: 100%;
    height: 341px;
  }

  @media (max-width: ${size.md}) {
    min-width: 100%;
  }
`

export const ImageTwoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 588px;
  height: 486px;

  @media (max-width: ${size.lg}) {
    min-width: 468px;
    height: 468px;
  }

  @media (max-width: ${size.md}) {
    min-width: 100%;
    height: 341px;
  }

  @media (max-width: ${size.md}) {
    min-width: 100%;
    order: 2;
  }
`

export const Wrapper = styled.div`
  background-color: #dfebf9;
`

export const TitleWrapper = styled.div`
  margin-bottom: 36px;

  @media (max-width: ${size.md}) {
    margin-bottom: 24px;
  }

  @media (max-width: ${size.sm}) {
    margin-bottom: 16px;
  }
`

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;

  @media (max-width: ${size.md}) {
    flex-direction: column;
  }
`
