import AutoThoughtsIcon from '../../../../static/img/Kpt/auto-thoughts-image.svg'
import AutoThoughtsIconMobile from '../../../../static/img/Kpt/auto-thoughts-mobile.svg'
import AutoThoughtsIconXS from '../../../../static/img/Kpt/auto-thoughts-xxs.svg'
import styled from 'styled-components'
import { size } from '../../../constants'

export const Content = styled.div`
  padding: 72px 0;
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;

  @media (max-width: ${size.md}) {
    padding: 48px 0;
    flex-direction: column;
  }

  @media (max-width: ${size.sm}) {
    padding: 32px 0;
    flex-direction: column;
  }
`

export const DescriptionWrapper = styled.div`
  flex: 1;
  color: ${(props) => props.color ?? '#333'};

  > * {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
`

export const AutoThoughtsImage = styled.img.attrs(() => ({}))`
  content: url(${AutoThoughtsIcon});
  width: 588px;
  height: 486px;

  @media (max-width: ${size.lg}) {
    content: url(${AutoThoughtsIcon});
    width: 468px;
    height: 468px;
  }

  @media (max-width: ${size.md}) {
    content: url(${AutoThoughtsIcon});
    width: 100%;
    height: 341px;
  }

  @media (max-width: ${size.sm}) {
    content: url(${AutoThoughtsIconMobile});
    height: 341px;
  }

  @media (max-width: ${size.xs}) {
    content: url(${AutoThoughtsIconXS});
  }
`

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Wrapper = styled.div`
  background-color: #2963a3;
`
export const Br = styled.br`
  @media (max-width: ${size.md}) {
    display: none;
  }
`

export const TitleSection = styled.div`
  max-width: 588px;
  color: #fff;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;

  @media (max-width: ${size.md}) {
    max-width: 100%;
  }

  @media (max-width: ${size.sm}) {
    font-size: 24px;
    line-height: 32px;
  }

  @media (max-width: ${size.xs}) {
    font-size: 18px;
    line-height: 26px;
  }
`

export const InfoContainer = styled.div``
