import React from 'react'
import {
  BlueCard,
  CardContainer,
  Content,
  DescriptionWrapper,
  ImageTwoWrapper,
  ImageWrapper,
  InfoContainer,
  ListItem,
  ListWrapper,
  SituationOneIcon,
  SituationThreeIcon,
  SituationTwoIcon,
  TitleBr,
  TitleSection,
  TitleSectionH3,
  Wrapper
} from './SituationSection.styles'
import { Grid, Text } from '../../../../youtalk-storybook/src/ui'
import { TitleWrapper } from '../Common.styles'

export const SituationSection = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <CardContainer>
            <InfoContainer>
              <TitleWrapper>
                <TitleSection>Ситуация: </TitleSection>
              </TitleWrapper>
              <DescriptionWrapper>
                <Text.Large semiBold>
                  две девушки прислали начальнику отчет.
                  <br />
                  Он отправил каждой сообщение:
                  <br />
                  «Надо доработать»
                </Text.Large>
              </DescriptionWrapper>
            </InfoContainer>
            <ImageWrapper>
              <SituationOneIcon />
            </ImageWrapper>
          </CardContainer>

          <CardContainer>
            <ImageTwoWrapper>
              <SituationTwoIcon />
            </ImageTwoWrapper>
            <InfoContainer>
              <TitleWrapper>
                <TitleSection>
                  Вот Ксюша, у нее нейтральные убеждения:
                </TitleSection>
              </TitleWrapper>
              <DescriptionWrapper>
                <Text.Large semiBold>
                  В нашей голове будто всплывают уведомления с сообщениями:
                </Text.Large>
                <Text.Large semiBold>
                  Возможно, она будет немного раздражена или загрустит, но в
                  какой-то момент сядет за отчет, сделает, отправит начальнику и
                  забудет обо всей ситуации. Для нее это обычный вторник.{' '}
                </Text.Large>
              </DescriptionWrapper>
            </InfoContainer>
          </CardContainer>

          <CardContainer>
            <InfoContainer>
              <TitleWrapper>
                <TitleSection>
                  А у Кристины —
                  <TitleBr />
                  негативные — и вот, что <TitleBr />
                  из этого получается…
                </TitleSection>
              </TitleWrapper>
              <DescriptionWrapper>
                <Text.Large semiBold>
                  Ей кажется, что мир – место, где приходится выживать. Другие
                  люди всегда готовы заметить её ошибки, напасть, критиковать. А
                  она неумелая и никчемная.{' '}
                </Text.Large>
                <Text.Large semiBold>
                  Получив такое же письмо, она подумала: «Боже мой! Как я могла
                  так ошибиться?! Почему я не перепроверила отчет еще раз?
                  Теперь начальник считает, что я совсем тупая и некомпетентная.
                  Он больше никогда не доверит мне серьезные задачи и скоро
                  уволит. Я никогда больше не найду хорошую работу, мне придется
                  работать дворником. Я не смогу добиться своих целей. Моя жизнь
                  разрушена!».
                </Text.Large>
              </DescriptionWrapper>
            </InfoContainer>
            <ImageWrapper>
              <SituationThreeIcon />
            </ImageWrapper>
          </CardContainer>

          <CardContainer>
            <InfoContainer>
              <BlueCard>
                <TitleWrapper>
                  <TitleSectionH3>
                    Эти мысли вызовут у нее много интенсивных эмоций:
                  </TitleSectionH3>
                </TitleWrapper>
                <DescriptionWrapper>
                  <ListWrapper>
                    <ListItem>
                      <Text.Large semiBold>
                        <b>Злость на себя</b>, ведь это она виновата в крахе
                        своей карьеры.
                      </Text.Large>
                    </ListItem>
                    <ListItem>
                      <Text.Large semiBold>
                        <b>Тревогу или горечь</b>, ведь жизнь разрушена.
                      </Text.Large>
                    </ListItem>
                    <ListItem>
                      <Text.Large semiBold>
                        <b>Обиду или злость на начальника</b>, ведь это он
                        спровоцировал весь этот ужас.
                      </Text.Large>
                    </ListItem>
                  </ListWrapper>
                </DescriptionWrapper>
              </BlueCard>
            </InfoContainer>

            <InfoContainer>
              <TitleWrapper>
                <TitleSectionH3>
                  Её эмоции повлияют
                  <TitleBr />
                  на ее действия
                </TitleSectionH3>
              </TitleWrapper>
              <DescriptionWrapper>
                <Text.Large semiBold>
                  <b>Вариант 1.</b> Девушка начнет по сто раз перепроверять
                  отчеты, чтобы ни в коем случае не допустить ни одной ошибки.
                  Бесконечные проверки будут занимать слишком много времени, и
                  девушка провалит дедлайны.
                </Text.Large>
                <Text.Large semiBold>
                  <b>Вариант 2.</b> Ей станет сложно даже приступить к работе:
                  не ошибается тот, кто ничего не делает. Прокрастинация сделает
                  свое: дедлайны опять будут провалены.
                </Text.Large>
              </DescriptionWrapper>
            </InfoContainer>
          </CardContainer>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
