import React from 'react'
import lightIcon from '../../../../static/img/Kpt/light.svg'
import styled from 'styled-components'
import {
  Container,
  Content,
  DescriptionWrapper,
  IconWrapper,
  TextWrapper,
  TitleCard,
  Wrapper
} from './WhoIsSuitable.styles'
import { Grid, Text } from '../../../../youtalk-storybook/src/ui'

export const LightSVG = styled.img.attrs(() => ({
  src: lightIcon
}))``

export const WhoIsSuitable = () => (
  <Wrapper>
    <section>
      <Grid>
        <Container>
          <Content>
            <IconWrapper>
              <LightSVG />
            </IconWrapper>
            <TextWrapper>
              <TitleCard>
                Кому подойдет когнитивно-поведенческая терапия?
              </TitleCard>
              <DescriptionWrapper>
                <Text.Large semiBold>
                  КПТ подойдет людям практического склада ума, которые хотят
                  понять, как работает их психика, проследить логику своих
                  действий и изучить механизм психотерапевтической работы.
                </Text.Large>
                <Text.Large semiBold>
                  Как и в любом другом подходе для успеха КТП важны мотивация и
                  готовность клиента работать над собой.
                </Text.Large>
              </DescriptionWrapper>
            </TextWrapper>
          </Content>
        </Container>
      </Grid>
    </section>
  </Wrapper>
)
