import sessionOnlineIcon from '../../../../static/img/Kpt/sessions-online.svg'
import sessionOnlineIcon1024 from '../../../../static/img/Kpt/sessions-online-1024.svg'
import sessionOnlineIcon320 from '../../../../static/img/Kpt/sessions-online-320.svg'
import sessionOnlineIcon414 from '../../../../static/img/Kpt/sessions-online-414.svg'
import sessionOnlineIcon768 from '../../../../static/img/Kpt/sessions-online-768.svg'
import styled from 'styled-components'
import { Button } from '../../../../youtalk-storybook/src/ui'
import { size } from '../../../constants'

export const Wrapper = styled.div`
  background-color: #dfebf9;
`

export const ListWrapper = styled.ul`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  padding-left: 25px;
  list-style-type: disc;
`
export const ListItem = styled.li`
  gap: 8px;
  color: #333;
`

export const SessionImage = styled.img.attrs(() => ({}))`
  content: url(${sessionOnlineIcon});

  @media (max-width: ${size.lg}) {
    content: url(${sessionOnlineIcon1024});
  }

  @media (max-width: ${size.md}) {
    content: url(${sessionOnlineIcon768});
  }

  @media (max-width: ${size.sm}) {
    content: url(${sessionOnlineIcon414});
  }

  @media (max-width: ${size.xs}) {
    content: url(${sessionOnlineIcon320});
  }
`

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Content = styled.div`
  padding: 72px 0;
  display: flex;
  gap: 24px;
  align-items: center;

  @media (max-width: ${size.md}) {
    padding: 48px 0;
    flex-direction: column-reverse;
  }

  @media (max-width: ${size.sm}) {
    padding: 32px 0;
  }

  @media (max-width: ${size.xs}) {
    gap: 16px;
  }
`

export const TitleSection = styled.div`
  color: #333333;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  max-width: 588px;
  margin-bottom: 24px;

  @media (max-width: ${size.lg}) {
    margin-bottom: 16px;
  }

  @media (max-width: ${size.md}) {
    max-width: 100%;
  }

  @media (max-width: ${size.sm}) {
    font-size: 24px;
    line-height: 32px;
  }

  @media (max-width: ${size.xs}) {
    font-size: 18px;
    line-height: 26px;
  }
`

export const TitleSectionH3 = styled.div`
  color: #333333;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;

  @media (max-width: ${size.sm}) {
    font-size: 18px;
    line-height: 26px;
  }

  @media (max-width: ${size.xs}) {
    font-size: 16px;
    line-height: 24px;
  }
`

export const DescriptionWrapper = styled.div`
  color: ${(props) => props.color ?? '#333'};

  > * {
    &:not(:last-child) {
      margin-bottom: 24px;

      @media (max-width: ${size.xs}) {
        margin-bottom: 16px;
      }
    }
  }
`

export const Br = styled.br`
  @media (max-width: ${size.md}) {
    display: none;
  }
`

export const InfoContainer = styled.div`
  max-width: 588px;

  @media (max-width: ${size.lg}) {
    max-width: 468px;
  }

  @media (max-width: ${size.md}) {
    max-width: 100%;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;

  @media (max-width: ${size.md}) {
    display: block;

    ${Button.NewPrimary}, ${Button.NewOutline} {
      width: 100%;
    }
  }
`
