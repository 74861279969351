import React from 'react'
import { Button, Grid, Text } from '../../../../youtalk-storybook/src/ui'
import {
  ButtonsWrapper,
  Content,
  DescriptionWrapper,
  ImageWrapper,
  InfoContainer,
  ListItem,
  ListWrapper,
  SessionImage,
  TitleSection,
  Wrapper
} from './SessionsOnline.styles'
import { Event as GAEvent } from '../../../components/GA'

export const SessionsOnline = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <InfoContainer>
            <TitleSection>
              Сессии с психологами YouTalk проходят онлайн
            </TitleSection>
            <DescriptionWrapper>
              <ListWrapper>
                <ListItem>
                  <Text.Large semiBold>
                    Вы назначаете сессию в удобное время
                  </Text.Large>
                </ListItem>
                <ListItem>
                  <Text.Large semiBold>
                    Экономите время на дорогу до кабинета и обратно
                  </Text.Large>
                </ListItem>
                <ListItem>
                  <Text.Large semiBold>
                    Работаете с психологом в комфортной и привычной обстановке
                  </Text.Large>
                </ListItem>
                <ListItem>
                  <Text.Large semiBold>
                    Можете бесплатно отменить или перенести сессию за 24 часа
                  </Text.Large>
                </ListItem>
              </ListWrapper>
              <ButtonsWrapper>
                <Button.NewPrimary
                  href="/wizard/"
                  onClick={GAEvent.openSemeinyiPageFirstScreen}
                  size="large"
                  type="link"
                >
                  Подобрать психолога
                </Button.NewPrimary>
              </ButtonsWrapper>
            </DescriptionWrapper>
          </InfoContainer>
          <ImageWrapper>
            <SessionImage alt="Сессии с психологами YouTalk проходят онлайн" />
          </ImageWrapper>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
