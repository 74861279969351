import React from 'react'
import {
  ComponentOneSvg,
  ComponentThreeSvg,
  ComponentTwoSvg,
  Content,
  Dash,
  InfoCard,
  InfoCardDescription,
  InfoCardFullSize,
  InfoCardImageHeader,
  InfoCardTextContainer,
  InfoCardTitle,
  InfoCardsContainer,
  TitleSection,
  TitleWrapper,
  Wrapper
} from './KptComponents.style'
import { Grid } from '../../../../youtalk-storybook/src/ui'

export const KptComponents = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <TitleWrapper>
            <TitleSection>
              С точки зрения КПТ, психика состоит из трех основных компонентов
            </TitleSection>
          </TitleWrapper>
          <InfoCardsContainer>
            <InfoCard>
              <InfoCardImageHeader>
                <ComponentOneSvg />
              </InfoCardImageHeader>
              <InfoCardTextContainer>
                <InfoCardTitle>1. Эмоции </InfoCardTitle>
                <InfoCardDescription>
                  Это реакция нашего организма на происходящее
                </InfoCardDescription>
              </InfoCardTextContainer>
            </InfoCard>
            <InfoCard>
              <InfoCardImageHeader>
                <ComponentTwoSvg />
              </InfoCardImageHeader>
              <InfoCardTextContainer>
                <InfoCardTitle>2. Поведение </InfoCardTitle>
                <InfoCardDescription>
                  Наши действия в ответ на что-либо
                </InfoCardDescription>
              </InfoCardTextContainer>
            </InfoCard>
            <InfoCard>
              <InfoCardImageHeader>
                <ComponentThreeSvg />
              </InfoCardImageHeader>
              <InfoCardTextContainer>
                <InfoCardTitle>3. Когниции</InfoCardTitle>
                <InfoCardDescription>
                  Состоят из базовых убеждений и автоматических мыслей
                </InfoCardDescription>
              </InfoCardTextContainer>
            </InfoCard>
            <InfoCardFullSize>
              <InfoCardTextContainer>
                <InfoCardTitle>
                  Базовые убеждения <Dash>&ndash;&ndash;</Dash>
                </InfoCardTitle>
                <InfoCardDescription>
                  <p>
                    Это глобальные представления, которые формируются в основном
                    в детстве и влияют на то, как мы смотрим на мир, других
                    людей и себя.
                  </p>
                  <p>
                    Базовые убеждения бывают негативными или
                    нейтральными&#8288;/&#8288;позитивными
                  </p>
                </InfoCardDescription>
              </InfoCardTextContainer>
            </InfoCardFullSize>
          </InfoCardsContainer>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
