import React from 'react'
import styled from 'styled-components'
import vsSVG from '../../../../static/img/Kpt/vs.svg'
import {
  Container,
  Content,
  IconWrapper,
  TextWrapper,
  Wrapper
} from './ExtremeBeliefs.styles'
import { Grid, Text } from '../../../../youtalk-storybook/src/ui'

export const VsIcon = styled.img.attrs(() => ({
  src: vsSVG
}))``

export const ExtremeBeliefs = () => (
  <Wrapper>
    <section>
      <Grid>
        <Container>
          <Content>
            <IconWrapper>
              <VsIcon />
            </IconWrapper>
            <TextWrapper>
              <Text.Large bold>
                Эти два убеждения — крайности, показывающие, как сильно может
                различаться наш взгляд на одну и ту же ситуацию и к каким
                последствиями приводить
              </Text.Large>
            </TextWrapper>
          </Content>
        </Container>
      </Grid>
    </section>
  </Wrapper>
)
