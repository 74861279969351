import componentOne from '../../../../static/img/Kpt/component-1.svg'
import componentThree from '../../../../static/img/Kpt/component-3.svg'
import componentTwo from '../../../../static/img/Kpt/component-2.svg'

import styled from 'styled-components'
import { Button } from '../../../atoms/Button'
import { size } from '../../../constants'

export const Wrapper = styled.div`
  background: #2963a3;
`

export const Content = styled.div`
  padding: 72px 0;

  @media (max-width: ${size.md}) {
    padding: 48px 0;
  }

  @media (max-width: ${size.sm}) {
    padding: 32px 0;
  }
`

export const TitleSection = styled.div`
  max-width: 660px;
  color: #fff;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;

  @media (max-width: ${size.sm}) {
    max-width: 100%;
    font-size: 24px;
    line-height: 32px;
  }

  @media (max-width: ${size.xs}) {
    font-size: 18px;
    line-height: 26px;
  }
`

export const TitleWrapper = styled.div`
  margin-bottom: 32px;

  @media (max-width: ${size.md}) {
    margin-bottom: 24px;
  }
`

export const InfoCard = styled.div`
  padding: 24px;
  border-radius: 32px;
  background: #fff;

  @media (max-width: ${size.md}) {
    display: flex;
    gap: 16px;
    align-items: center;
  }

  @media (max-width: ${size.sm}) {
    flex-direction: column;
  }

  @media (max-width: ${size.xs}) {
    padding: 16px;
  }
`

export const InfoCardFullSize = styled.div`
  grid-column-start: 1;
  grid-column-end: 4;
  padding: 24px;
  display: flex;
  border-radius: 32px;
  background: #fff;
  gap: 16px;

  @media (max-width: ${size.sm}) {
    display: block;
  }
`

export const InfoCardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 24px;
  row-gap: 24px;

  @media (max-width: ${size.md}) {
    display: flex;
    flex-direction: column;
  }
`

export const ComponentOneSvg = styled.img.attrs(() => ({
  src: componentOne
}))`
  width: 183px;
  height: 183px;
`

export const ComponentTwoSvg = styled.img.attrs(() => ({
  src: componentTwo
}))`
  width: 183px;
  height: 183px;
`

export const ComponentThreeSvg = styled.img.attrs(() => ({
  src: componentThree
}))`
  width: 183px;
  height: 183px;
`

export const InfoCardImageHeader = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;

  @media (max-width: ${size.md}) {
    margin-bottom: 0;
  }
`

export const InfoCardTextContainer = styled.div`
  width: 100%;
`

export const InfoCardTitle = styled.div`
  font-family: Montserrat, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  color: #333;
  margin-bottom: 8px;

  @media (max-width: ${size.sm}) {
    font-size: 18px;
    line-height: 26px;
  }

  @media (max-width: ${size.xs}) {
    font-size: 16px;
    line-height: 24px;
  }
`
export const InfoCardDescription = styled.div`
  max-width: 750px;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  color: #333;

  @media (max-width: ${size.md}) {
    max-width: 670px;
  }

  @media (max-width: ${size.sm}) {
    font-size: 16px;
    line-height: 24px;
  }

  p {
    margin-bottom: 8px;
  }

  p:last-child {
    margin-bottom: 0;
  }
`

export const ButtonContainer = styled.div`
  ${Button.NewPrimary} {
    margin: auto;

    @media (max-width: ${size.xs}) {
      width: 100%;
    }
  }
`

export const Dash = styled.span`
  display: none;

  @media (max-width: ${size.sm}) {
    display: inline-block;
  }
`
