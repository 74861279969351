import React from 'react'
import styled from 'styled-components'
import {
  BlockContainer,
  Content,
  DescriptionWrapper,
  ExampleOneImage,
  ExampleTwoImage,
  ImageOneWrapper,
  ImageTwoWrapper,
  InfoContainer,
  QuoteText,
  TitleSection,
  TitleWrapper,
  Wrapper
} from './ExamplesSection.styles'
import { Grid, Text } from '../../../../youtalk-storybook/src/ui'
import { size } from '../../../constants'

const CustomGrid = styled(Grid)`
  @media (max-width: ${size.md}) {
    padding: 0 10px;
  }

  @media (max-width: ${size.sm}) {
    padding: 0 20px;
  }

  @media (max-width: ${size.xs}) {
    padding: 0 16px;
  }
`

export const ExamplesSection = () => (
  <Wrapper>
    <section>
      <CustomGrid>
        <Content>
          <TitleWrapper>
            <TitleSection>Вот несколько примеров:</TitleSection>
          </TitleWrapper>
          <BlockContainer>
            <InfoContainer>
              <div>
                <DescriptionWrapper>
                  <Text.Large semiBold>Негативные убеждения о мире:</Text.Large>
                  <QuoteText>
                    «Мир небезопасный. В любой момент может случиться что
                    угодно»
                  </QuoteText>
                </DescriptionWrapper>
                <DescriptionWrapper>
                  <Text.Large semiBold>
                    Негативные убеждения о других людях:
                  </Text.Large>
                  <QuoteText>
                    «Они критикующие, опасные, всегда готовы напасть»
                  </QuoteText>
                </DescriptionWrapper>
                <DescriptionWrapper>
                  <Text.Large semiBold>Негативные убеждения о себе:</Text.Large>
                  <QuoteText>
                    «Я никчемный, неудачливый, неспособный, плохой»
                  </QuoteText>
                </DescriptionWrapper>
              </div>
              <ImageOneWrapper>
                <ExampleOneImage />
              </ImageOneWrapper>
            </InfoContainer>

            <InfoContainer>
              <ImageTwoWrapper>
                <ExampleTwoImage />
              </ImageTwoWrapper>
              <div>
                <DescriptionWrapper>
                  <Text.Large semiBold>
                    Нейтральные убеждения о мире:
                  </Text.Large>
                  <QuoteText>«Мир в целом безопасный»</QuoteText>
                </DescriptionWrapper>
                <DescriptionWrapper>
                  <Text.Large semiBold>
                    Нейтральные убеждения о других людях:
                  </Text.Large>
                  <QuoteText>
                    «Другие люди относятся ко мне нейтрально или дружелюбно»
                  </QuoteText>
                </DescriptionWrapper>
                <DescriptionWrapper>
                  <Text.Large semiBold>
                    Нейтральные убеждения о себе:
                  </Text.Large>
                  <QuoteText>«Я умный, способный, сообразительный»</QuoteText>
                </DescriptionWrapper>
              </div>
            </InfoContainer>
          </BlockContainer>
        </Content>
      </CustomGrid>
    </section>
  </Wrapper>
)
