import React from 'react'
import {
  Content,
  DescriptionWrapper,
  HeroImage,
  ImageWrapper,
  InfoContainer,
  Wrapper
} from './QueriesKpt.styles'
import { Grid, Text, Title } from '../../../../youtalk-storybook/src/ui'
import { TitleWrapper } from '../Common.styles'

export const QueriesKpt = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <ImageWrapper>
            <HeroImage alt="Запросы, с которыми работают психологи КПТ" />
          </ImageWrapper>
          <InfoContainer>
            <TitleWrapper>
              <Title.H2>Запросы, с которыми работают психологи КПТ</Title.H2>
            </TitleWrapper>
            <DescriptionWrapper>
              <Text.Large semiBold>
                Метод эффективен в работе с запросами, которые можно
                сформулировать в виде конкретной цели: например, «Хочу сделать
                отношения с партнером более доверительными» или «Хочу в
                следующий год перейти на новую ступень своей карьеры».
              </Text.Large>
              <Text.Large semiBold>
                Техники КПТ помогают справиться с тревогой, депрессией,
                бессонницей, прокрастинацией, перфекционизмом.
              </Text.Large>
              <Text.Large semiBold>
                Единственное ограничение — КПТ не будет очень полезным в
                запросах, связанных с самоисследованием, самопознанием.
              </Text.Large>
            </DescriptionWrapper>
          </InfoContainer>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
