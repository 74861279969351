import styled from 'styled-components'
import { size } from '../../../constants'

export const Wrapper = styled.div`
  background: #dfebf9;
  padding-bottom: 124px;
  padding-top: 72px;

  @media (max-width: ${size.md}) {
    padding-bottom: 100px;
    padding-top: 48px;
  }

  @media (max-width: ${size.sm}) {
    padding-bottom: 83px;
    padding-top: 32px;
  }

  @media (max-width: ${size.xs}) {
    padding-bottom: 86px;
    padding-top: 32.5px;
  }
`

export const Container = styled.div`
  display: flex;
  background: #dfebf9;
  padding-top: 72px;
  gap: 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Content = styled.div`
  width: 792px;
  padding: 40px 16px;
  border-radius: 24px;
  border: 1px solid #dedede;
  background: white;

  @media (max-width: ${size.lg}) {
    width: 632px;
    padding: 40px 24px;
  }

  @media (max-width: ${size.sm}) {
    width: 100%;
    padding: 40px 16px;
  }
`
export const TitleCard = styled.h2`
  color: #333;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;

  @media (max-width: ${size.sm}) {
    font-size: 18px;
    line-height: 26px;
  }

  @media (max-width: ${size.xs}) {
    font-size: 16px;
    line-height: 24px;
  }
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 16px;
`

export const DescriptionWrapper = styled.div`
  color: ${(props) => props.color ?? '#333'};

  > * {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
`

export const IconWrapper = styled.div`
  text-align: center;
  margin-top: -95px;
  margin-bottom: 2px;
`
